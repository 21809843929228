.JewelleryCare {
  display: flex;
  padding: 48px 72px 80px;
  gap: 24px;
  flex-direction: column;
}

.JC_Container {
  padding: 64px;
  display: flex;
  flex-direction: column;
  gap: 40px;
  text-align: left;
  color: white;
  max-width: 600px;
  margin: 0 auto;
}

.JC_bullets {
  list-style-type: none;
  /* Remove default bullets */
  padding-left: 28px;
  /* Add some padding for bullets */
}

.JC_bullets li {
  position: relative;
  margin: 16px 0;
}

.JC_bullets li::before {
  content: '';
  position: absolute;
  left: -15px;
  /* Adjust this to align the diamond */
  top: 14px;
  transform: translateY(-50%) rotate(45deg);
  /* Rotate the square to make it a diamond */
  width: 10px;
  /* Size of the diamond */
  height: 10px;
  /* Size of the diamond */
  background-color: var(--primary);
  /* Color of the diamond */
}

@media only screen and (orientation: portrait) {
  .JewelleryCare {
    padding: 48px 20px 80px;
  }

  .JC_Container {
    padding: 20px;
  }
}