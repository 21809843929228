.ProductPage{
    padding: 48px 0px 72px;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 36px;
    /* margin: 0 auto; */
    /* max-width: 1300px; */
}

.productPageGrid{
    display: grid;
    grid-template-columns: calc(50% - 36px) calc(50% - 36px);
    gap: 72px;
    justify-items: space-between;
    text-align: left;
    color: white;
    /* max-width: 1200px; */
    /* margin: 0 auto; */
}

.PP_imgDiv{
    width: calc(100% - 72px);
    align-self: center;
    background-color: var(--black);
    margin-left: 72px;
    display: flex;
    align-items: center;
    justify-content: center;
    aspect-ratio: 1 / 1;
    height: fit-content;
}

.PP_imgDiv img {
    max-width: 100%;
    max-height: 500px;
    margin: 0 auto;
    height: 100% !important;
    width: auto !important;
    object-fit: cover;
}

.PP_mainInfo{
    margin-right: 72px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: space-between;
    width: calc(100% - 72px);
    max-width: 490px;
    justify-self: end;
}

.PP_selectFlex{
    display: flex;
    width: 100%;
}
.PP_selectLabel{
    text-align: left;
    width: 100%;
    border-bottom: 1px solid white;
}
.PP_select{
    width: 100%;
    font-size: 18px;
}

.PP_actionBtn {
    border-radius: 0;
    width: 100%;
    font-size: 18px;
    background-color: var(--primary);
    color: var(--secondary);
    justify-content: center;
    min-height: 36px;
    font-family: 'body';
}

.PP_actionBtn:hover {
    background-color: white;
    color: var(--primary);
}

.PP_subInfo{
    margin-left: 72px;
}

.PP_subImg{
    width: calc(100%);
    max-width: 560px;
    place-self: end;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.PP_subImg a{
    width: 100%;
}

.PP_subImg img {
    max-width: 100%;
    width: 100%;
    height: 80%;
    flex-grow: 1;
    /* height: 100%; */
    object-fit: contain;
}

.pinkHoverA{
    border-bottom: 1px solid white;
    cursor: pointer;
    width: fit-content;
    font-family: 'eb garamond';
}

.pinkHoverA:hover{
    border-bottom: 1px solid var(--primary);
    color: var(--primary);
}

@media only screen and (max-width: 760px) {

    .ProductPage{
        /* gap: 60px; */
        font-size: 14px;
    }
    
    .productPageGrid{
        grid-template-columns: 1fr;
        gap: 32px;
        justify-items: space-between;
    }
    
    .PP_imgDiv{
        width: calc(100% - 40px);
        margin-left: 20px;
        margin-right: 20px;
    }
    
    .PP_mainInfo{
        margin-left: 20px;
        margin-right: 20px;
        gap: 20px;
        width: calc(100% - 40px);
    }
    
    .PP_select{
        font-size: 16px;
    }
    
    .PP_actionBtn {
        font-size: 16px;
        min-height: 32px;
        width: 80%;
        margin: 0 auto
    }
    
    .PP_subInfo{
        margin-left: 20px;
        margin-right: 20px;
    }

    .PP_mobileWidthDec{
        width: 80%;
        margin: 0 auto;
    }

    .PP_subImg a{
        padding: 0 20px;
        width: 100%;
    }
    
    .PP_subImg img {
        max-width: 100%;
        width: 100%;
        height: 80%;
        flex-grow: 1;
        /* height: 100%; */
        object-fit: contain;
    }
}
