.privacy-policy-container {
    max-width: 1100px;
    margin: 0 auto;
    padding: 60px 72px ;
    font-family: 'body';
    color: white;
    line-height: 1.6;
    text-align: justify;
}

.policy-section {
    margin-bottom: 20px;
}

.policy-paragraph {
    margin-bottom: 15px;
}

.policy-list {
    list-style-type: disc;
    padding-left: 20px;
    margin-top: 10px;
    margin-bottom: 20px;
}

.policy-bullet {
    margin-bottom: 10px;
}

.privacy-policy-container h1 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 1.8rem;
}

.privacy-policy-container h2 {
    font-size: 1.4rem;
    margin-bottom: 10px;
}

@media only screen and (orientation: portrait) {
    .privacy-policy-container {
        padding: 60px 20px ;
    }
    
    .policy-list {
        padding-left: 20px;
    }
}