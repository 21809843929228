.Collection{
    padding: 48px 72px 72px;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 36px;
    margin: 0 auto;
    max-width: 1300px;
}

.custom-shadow {
    box-shadow: 0px 5px 10px 0px #0000001A;
}

.custom-bg {
    background-color: rgba(24, 0, 10, 0.664);
}

.productCarousel{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 24px;
    row-gap: 32px;
}

.ProductDiv {
    background-color: white;
    cursor: pointer;
    position: relative;
}

.PD_imgDiv {
    height: 220px;
    align-self: center;
}

.PD_imgDiv span {
    height: 100%;
    width: 100%;
}

.PD_imgDiv img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.PD_Content{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-transform: uppercase;
    color: #969696;
    padding: 4px 10px;
    text-align: left;
    position: absolute;
    inset: 0;
    height: 100%;
    width: 100%;
}


.PD_carat {
    border-bottom: 1px solid transparent;
    cursor: pointer;
}

.PD_carat:hover {
    border-bottom: 1px solid var(--primary);
    color: var(--primary);
}

.PD_desc {
    padding: 12px 16px;
    color: white;
    text-align: left;
    font-size: 11px;
    font-weight: 300;
    height: 46px;
    overflow-y: hidden;
    font-family: 'bodyone';
}

.PD_actions {
    padding: 12px 0px;
    color: white;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.PD_actionBtn {
    border-radius: 0;
    width: 100%;
    font-size: 14px;
    background-color: white;
    color: var(--secondary);
    justify-content: center;
    height: 30px;
}

.PD_actionBtn:hover {
    background-color: var(--primary);
    color: white;
}

.C_shopBtns{
    background-color: white;
    color: var(--secondary);
}
.C_shopBtns.selected{
    background-color: var(--primary);
    color: white;
}
.C_shopBtns:hover{
    background-color: var(--primary);
    color: white;
}

.seemoreLink{
    display: flex;
    justify-content: center;
    text-transform: uppercase;
    color: var(--secondary);
    color: #969696;
    text-decoration: underline;
    cursor: pointer;
}
.seemoreLink:hover{
    color: var(--primary);
}


.AddedToCartModalContainer{
    position: fixed;
    inset: 0;
    background-color: rgba(255, 255, 255, 0.603);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
}

.AddedToCartModal{
    padding: 32px 32px 24px;
    margin: 24px;
    background-color: var(--primary);
    color: white;
    position: relative;
    width: 100%;
    max-width: 400px;
    text-align: center;
}

.modal_imgDiv{
    height: 100px;
    background-color: var(--secondary);
    width: fit-content;
}
.modal_imgDiv>img{
    object-fit: contain;
    max-height: 100%;
}


@media only screen and (max-width: 960px) {
    .Collection{
        padding: 48px 60px 72px;
    }
    
    .productCarousel{
        grid-template-columns: repeat(3, 1fr);
        column-gap: 24px;
        /* row-gap: 48px; */
    }
    
    .PD_imgDiv {
        /* height: 150px; */
    }
    
    .PD_actionBtn {
        width: 100%;
        height: 30px;
    }
    
    .AddedToCartModal{
        padding: 32px 32px 24px;
        margin: 24px;
        width: 100%;
    }
    
    .modal_imgDiv{
        height: 100px;
    }

    .C_shopBtns{
        font-size: 14px;
    }

}


@media only screen and (orientation: portrait){
    .Collection{
        padding: 48px 20px 72px;
    }
    
    .productCarousel{
        grid-template-columns: repeat(2, 1fr);
        column-gap: 16px;
        row-gap: 10px;
    }
    
    .PD_imgDiv {
        /* height: 160px; */
        width: 100%;
        align-self: center;
    }
    
    .PD_imgDiv span {
        width: 100%;
    }

    .PD_imgDiv img {
        width: 100%;
        object-fit: cover;
    }
    
    .PD_carat {
        display: none;
    }
    
    .PD_desc {
        /* display: none; */
    }
    
    .PD_actions {
        
    }
    
    .PD_actionBtn {
        width: 100%;
        height: 30px;
    }
    
    
    .AddedToCartModalContainer{
       
    }
    
    .AddedToCartModal{
        padding: 32px 20px 24px;
        margin: 24px;
        width: calc(100% - 64px);
    }
    
    .modal_imgDiv{
        height: 100px;
    }
    
    .C_shopBtns{
        display: none;
    }
    .C_shopBtns.mobile{
        display: block;
    }
}

@media only screen and (max-width: 500px) {
    .PD_imgDiv{
        height: 160px;
    }
}