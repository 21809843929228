.OntheMove{
    display: flex;
    padding: 48px 0px 80px;
    gap: 24px;
    flex-direction: column;
    text-align: left;
}

.onthemoveGrid{
    /* grid grid-cols-2 px-4 */
    display: grid;
    grid-template-columns: 49% 55% ;
    padding: 0 88px;
    gap: 4px;
}

.onthemoveGrid2{
    display: grid;
    grid-template-columns: 49.5% 49.5% ;
    padding: 0px;
    gap: 1%;
}

@media only screen and (orientation: portrait) {
       
    .onthemoveGrid{
        grid-template-columns: 1fr ;
        padding: 0 20px;
    }
}