.CartSlider{
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0; 
    background-color: #00000059;
    z-index: 10;
    display: flex;
    justify-content: flex-end;
    max-height: 100vh;
}

.Cart{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 8px;
    padding: 40px 56px 20px;
    /* min-height: calc(100vh - 69px); */
    background-color: white;
    width: 100%;
    max-width: 420px;
    text-align: left;
}

.closeCartBtn{
    text-align: right;
    position: absolute;
    right: 32px;
    top: 0px;
    cursor: pointer;
    font-size: 40px;
    color: var(--secondary);
}

.PlaceOrderBtns{
    display: flex;
    flex-direction: column;
    gap: 16px;
    color: var(--secondary);
}

.cartIcon{
    height: 16px;
    display: flex;
    justify-content: center;
}
.cartIcon img{
    max-height: 100%;
    object-fit: contain;
}


.cart_actionBtn {
    border-radius: 0;
    width: 100%;
    font-size: 14px;
    background-color: var(--secondary);
    color: white;
    justify-content: center;
    height: 30px;
}

.cart_actionBtn:hover {
    background-color: var(--primary);
    color: white;
}

.cartItemsList{
    overflow-y: scroll;
    max-height: calc(100vh - 220px);
}

.cartItemsList::-webkit-scrollbar {
    display: none;
}
