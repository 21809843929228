/* bg-[var(--black)] absolute top-[80px] left-0 right-0 py-6 px-16 flex flex-col items-start z-3 navAnim active custom-shadow font-body */

.NavContainer {
    position: absolute;
    top: 80px;
    left: 0;
    right: 0;
    padding: 0 70px;
    z-index: 3;
}

.NavMobileContainer {
    display: none;
    position: absolute;
    top: 80px;
    left: 0;
    right: 0;
    padding: 0 70px;
    z-index: 3;
}

.navAnim1 {
    max-height: 0;
    overflow: hidden;
    opacity: 0;
    transition: all 300ms ease;
}

.navAnim1.active {
    opacity: 1;
    height: 100vh;
    max-height: 150vh;
    overflow: visible;
}

.NavMenu {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-items: start;
    text-align: left;
    /* position: absolute;
    top: 0px;
    left: 72px;
    right: 72px; */
    padding: 48px 72px;
    /* z-index: 3; */
    color: var(--secondary);
    background-color: white;
    font-family: 'body';
    box-shadow: 0px 5px 10px 0px #0000001A;
}

.navAnim {
    max-height: 0;
    overflow: hidden;
    opacity: 0;
    transition: all 300ms ease;
}

.navAnim.active {
    opacity: 1;
    height: auto;
    max-height: 100vh;
    overflow: hidden;
}

.navItemExpand {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.navItemExpand2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 20px;
    padding-left: 20px;
}

.navItemExpandMobile {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.navHead {
    font-family: 'heading';
    font-size: 48px;
    margin-bottom: 24px;
    cursor: pointer;
}

.navItem {
    border-bottom: 2px solid transparent;
    transition: all 200ms ease-in-out;
    margin: 4px 0;
    text-transform: uppercase;
    width: fit-content;
    cursor: pointer;
    /* font-size: 14px; */
}

.navItem:hover {
    color: var(--primary);
    border-bottom: 2px solid var(--primary);
}

.navItem.selected {
    color: var(--primary);
    border-bottom: 2px solid var(--primary);
}

.navMobileSearch{
    display: none;
    position: absolute;
    top: 80px;
    left: 0px;
    right: 0px;
    padding: 10px 20px;
    justify-content: stretch;
    background-color: var(--black);
}
.navMobileSearch input {
    width: 100%;
}
.navMobileSearch button {
    width: fit-content;
}

@media only screen and (orientation: portrait) {
    .NavContainer {
        display: none;
    }

    .NavMobileContainer {
        top: 80px;
        padding: 0 0px;
        display: block;
    }

    .NavMenu {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        padding: 64px 48px;
        gap: 48px;
    }

    .navHead {
        font-size: 48px;
    }

    .navItemExpand {
        padding-left: 20px;
        align-items: flex-start;
    }

    .navSearch{
        display: none;
    }

    .navMobileSearch {
        display: flex;
    }
}