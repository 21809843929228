.CartItem {
    display: grid;
    grid-template-columns: 2fr 3fr;
    gap: 8px;
    color: var(--black);
    padding: 2px 0;
}

.cartItemImg {
    min-height: 100%;
    /* box-shadow: 0px 5px 10px 0px #0000001A; */
    background-color: var(--secondary);
    display: flex;
    justify-content: center;
    align-items: center;
}

.cartItemImg>img {
    object-fit: contain;
    object-position: center;
    max-width: 100%;
    max-height: 100%;
    object-position: center;
}

.cartItemInfo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */
    gap: 0px;
    padding: 8px;
    font-size: 12px;
}

/* .editCartQtyDiv{
    display: flex;
    gap: 8px;
    justify-content: center;
    align-items: center;
    margin: 8px 0;
} */

.cartItemDesc{
    height: 38px;
    overflow-y: hidden;
}