.ShopCollection{
    display: flex;
    padding: 48px 0px 80px;
    gap: 24px;
    flex-direction: column;
    text-align: left;
}

.SC_shopLink{
    /* font-weight: 700; */
    border-bottom: 1px solid var(--primary);
}