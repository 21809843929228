.formGrid{
    /* grid grid-cols-2 gap-[5%] w-full mt-8 mb-24 */
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 5%;
    width: 100%;
}

.formInput{
    /* w-[100%] text-[var(--primary)] my-2 font-subheading */
    width: 100%;
    color: var(--primary);
    margin: 8px 0;
    font-family: "EB Garamond";
    text-align: left;
}

.scroll-hide::-webkit-scrollbar {
    display: none;
}