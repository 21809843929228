.ProductDisplay{
    display: flex;
    flex-direction: column;
    gap: 100px;
    padding: 80px 72px 0px;
    min-height: calc(85vh);
    max-height: calc(85vh);
    margin: 40px 0 0;
}

.ProductDisplayPortable{
    display: flex;
    flex-direction: column;
    gap: 0px;
    padding: 40px 72px 0px;
    /* min-height: calc(95vh); */
    /* max-height: calc(95vh); */
    margin: 0px 0 0;
}

.PD_shopBtns{
    background-color: white;
    font-size: 12px;
    height: 24px;
}
.PD_shopBtns.selected{
    background-color: var(--primary);
}
.PD_shopBtns:hover{
    background-color: var(--primary);
}

.swiper-button-prev::after, .swiper-button-next::after{
    color: transparent;
    background-color: black;
    height: 22px;
    width: 24px;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    background-size: contain;
    background-repeat: no-repeat;
    z-index: 10;
}

.swiper-button-prev::after{
    background-image: url(../../../assets/icons/left.png);
    /* transform: translateX(-24px); */
}
.swiper-button-next::after{
    background-image: url(../../../assets/icons/right.png);
}

.ProductDisplay .swiper , .ProductDisplayPortable .swiper{
    /* margin: 0 72px; */
}


@media only screen and (orientation: portrait){
    .ProductDisplay{
        gap: 70px;
        min-height: unset;
        padding: 60px 20px 40px;
    }

    .ProductDisplayPortable{
        padding: 20px 20px 0px;
    }
    
    .PD_shopBtns{
        display: none;
    }
    .PD_shopBtns.mobile{
        display: block;
    }

}