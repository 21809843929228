.SizeGuide{
    display: flex;
    padding: 48px 72px 80px;
    gap: 24px;
    flex-direction: column;
    text-align: left;
}

.SG_row{
    margin: 4px 0;
    padding: 4px 0;
    border-bottom: 0.5px solid var(--primary);
    color: white;
}

@media only screen and (orientation: portrait) {
    .SizeGuide{
        padding: 48px 20px 80px;
    }
    
    .SG_row{
        margin: 4px 0;
        padding: 4px 0;
        border-bottom: 0.5px solid var(--primary);
        color: white;
    }
}