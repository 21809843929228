.CustomEngRings {
    display: flex;
    padding: 48px 72px 80px;
    gap: 24px;
    flex-direction: column;
}

.option:hover{
    background-color: var(--black) !important;
}

@media only screen and (orientation: portrait) {
    .CustomEngRings {
        display: flex;
        padding: 48px 20px 80px;
        gap: 24px;
        flex-direction: column;
    }
}