.FAQs {
    display: flex;
    padding: 48px 72px 120px;
    gap: 24px;
    flex-direction: column;
}

.title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}


.content {
    max-height: 0;
    overflow-y: hidden;
    transition: all 300ms ease-in-out;
    color: white;
    width: 100%;
    max-width: 800px;
}

.content.show {
    height: auto;
    max-height: 999px;
    /* max-height: 0px; */
    overflow-y: hidden;
    margin-top: 24px;
}

.faqArrows {
    height: 24px;
}
.faqArrows>img {
    height: 100%;
}

.faqhover{
    border-bottom: 1px solid white;
}

@media only screen and (orientation: portrait) {
    .FAQs {
        padding: 48px 20px 120px;
        gap: 24px;
    }
    
    .faqArrows {
        height: 20px;
    }
}